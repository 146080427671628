<script>
import GlobalVue from '@libs/Global.vue'
let $ = global.jQuery
window.jQuery = $
require('@fancyapps/fancybox')
require('@fancyapps/fancybox/dist/jquery.fancybox.css')

export default {
  extends: GlobalVue,
  props: {
    title: {default:"File Preview"},
    rel: String,
    src: [String,Array],
    label: String,
  },
  computed:{
    isZip(){
      let src = Array.isArray(this.src) ? this.src : [this.src]
      var extension = ""
      src.forEach(href=>{
        extension = href.substr((href.lastIndexOf('.') +1));
      })
      return (['zip','rar'].indexOf(extension.toLowerCase()) > -1)
    }
  },
  mounted: function(){
  },
  methods:{
    openPopup(){
      let config = []
      let src = Array.isArray(this.src) ? this.src : [this.src]
      src.forEach(href=>{
        var extension = href.substr((href.lastIndexOf('.') +1));
        if(['jpg','jpeg','png','gif','pdf'].indexOf(extension.toLowerCase()) < 0){
          window.open(href, "_blank")
          return true;
        }
        let type = ['jpg','jpeg','png','gif'].indexOf(extension.toLowerCase()) > -1 ? 'image' : 'iframe'
        let conf = { src: href, type: type }
        if(type=="iframe"){
          conf.width = this.isMobile ? "100%" : "90%"
          conf.height = "100%"
        }
        
        config.push(conf)
      })

			$.fancybox.open(config)
    }
  }
}
</script>

<template>
  <a href="javascript:;" @click="openPopup" v-if="$slots.default"><slot></slot></a>
  <a href="javascript:;" @click="openPopup" v-else class="btn_view" v-tooltip="title">
    <slot><i :class="{'fas fa-file-image':!isZip,'fas fa-file-alt':isZip}" style="font-size:40px"></i> {{label}}</slot>
  </a>
</template>

<style scoped>
.btn_view {
    display: block;
    color: #0145DC !important;
    width: 100%;
    border-radius: 0;
    text-align: center;
    padding: 7.5px 16px;
    border: 1px solid #0145DC;
    font-size: 14px;
    margin-bottom: 0px !important;
    transition: background 0.3s;
    cursor: pointer;
    height:auto
}
</style>